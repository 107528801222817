import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'

import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

// eslint-disable-next-line require-await
export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    release:"146a14fc2765f250605ad9c9fa7c6546d7cdd377",
    dsn:"https:\u002F\u002F6e82a3d67f584d8380aeeb0c4b9f34fc@o1319652.ingest.sentry.io\u002F4504100092575744",
    environment:"production",
    ignoreErrors:["ChunkLoadError","NavigationDuplicated","Navigation cancelled from ","via a navigation guard","Redirected when going from","_avast_submit","Target element","is not a valid selector","Load failed","Failed to fetch","Blocked a frame with origin","Expected \"id\" to be defined","window.norton.yellowToRel","Blocked 'connect' from 'adservice.google.com'",new RegExp("googleapis", "i")],
    denyUrls:[new RegExp("googleapis", "i"),new RegExp("maps\\.googleapis\\.com\\\u002Fmaps\\\u002Fapi\\\u002Fjs", "i"),"maps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs"],
    tracesSampleRate:1,
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}}),
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}

const middleware = {}

middleware['fetchLandingPages'] = require('../middleware/fetchLandingPages.ts')
middleware['fetchLandingPages'] = middleware['fetchLandingPages'].default || middleware['fetchLandingPages']

middleware['fetchLPData'] = require('../middleware/fetchLPData.ts')
middleware['fetchLPData'] = middleware['fetchLPData'].default || middleware['fetchLPData']

middleware['fetchMediaArticles'] = require('../middleware/fetchMediaArticles.ts')
middleware['fetchMediaArticles'] = middleware['fetchMediaArticles'].default || middleware['fetchMediaArticles']

middleware['fetchMiscData'] = require('../middleware/fetchMiscData.ts')
middleware['fetchMiscData'] = middleware['fetchMiscData'].default || middleware['fetchMiscData']

middleware['fetchPressData'] = require('../middleware/fetchPressData.ts')
middleware['fetchPressData'] = middleware['fetchPressData'].default || middleware['fetchPressData']

middleware['fetchTranslation'] = require('../middleware/fetchTranslation.ts')
middleware['fetchTranslation'] = middleware['fetchTranslation'].default || middleware['fetchTranslation']

middleware['routeMiddleware'] = require('../middleware/routeMiddleware.ts')
middleware['routeMiddleware'] = middleware['routeMiddleware'].default || middleware['routeMiddleware']

middleware['setArticleMetaData'] = require('../middleware/setArticleMetaData.ts')
middleware['setArticleMetaData'] = middleware['setArticleMetaData'].default || middleware['setArticleMetaData']

export default middleware
